import { MantineProvider, MantineTheme, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/dates/styles.css";
import Home from "./pages/Home";
import Navbar from "./layout/Navbar";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Book from "./pages/Book";
import Pricelist from "./pages/Pricelist";

const App = () => {
  const theme = createTheme({
    fontFamily: "Roboto, sans-serif",
    colors: {
      black: [
        "#f5f5f5", // Lightest shade (near white)
        "#e0e0e0",
        "#bdbdbd",
        "#9e9e9e",
        "#757575",
        "#616161",
        "#424242",
        "#303030",
        "#212121",
        "#000000", // Darkest shade, pure black
      ],
      white: [
        "#ffffff", // Pure white
        "#f5f5f5",
        "#eeeeee",
        "#e0e0e0",
        "#bdbdbd",
        "#9e9e9e",
        "#757575",
        "#616161",
        "#424242",
        "#303030", // Darker shade for contrast with white
      ],
      gray: [
        "#fafafa", // Lightest gray
        "#f5f5f5",
        "#eeeeee",
        "#e0e0e0",
        "#bdbdbd",
        "#9e9e9e",
        "#757575",
        "#616161",
        "#424242",
        "#303030", // Darkest gray
      ],
    },
    breakpoints: {
      xs: "500", // Extra small devices (portrait phones)
      sm: "768", // Small devices (landscape phones)
      md: "1024", // Medium devices (tablets)
      lg: "1280", // Large devices (desktops)
      xl: "1440", // Extra large devices (large desktops)
    },
    components: {
      Grid: {
        styles: {
          inner: {
            height: "100%", // Makes the Grid-inner take full height
            width: "100%", // Makes the Grid-inner take full width
          },
        },
      },
      Button: {
        styles: (
          theme: MantineTheme,
          params: { variant: "white" | "black" }
        ) => ({
          root: {
            color:
              params.variant === "white" ? theme.colors.dark[9] : theme.white,
            backgroundColor:
              params.variant === "white" ? theme.white : theme.colors.dark[9],
            borderRadius: "20px", // Set your desired border-radius
            padding: "12px 24px", // Adjust padding to align with the design
            "&:hover": {
              backgroundColor:
                params.variant === "white"
                  ? theme.colors.gray[1]
                  : theme.colors.dark[7],
            },
          },
        }),
      },
    },
  });

  return (
    <MantineProvider theme={theme}>
      <Navbar />
      <Home />
      <Products />
      <Pricelist />
      <Services />
      <Book />

      {/* Add other sections/components here */}
    </MantineProvider>
  );
};

export default App;
