import { useState } from "react";
import {
  Flex,
  Group,
  Input,
  Stack,
  Title,
  Button,
  Divider,
  Text,
  Container,
  Select,
} from "@mantine/core";
import { IconBrandWhatsapp, IconMapPin, IconPhone } from "@tabler/icons-react";
import FullBackground from "../layout/FullBackground";
import { useMediaQuery } from "@mantine/hooks";
import { DateTimePicker } from "@mantine/dates";

const Book = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [message, setMessage] = useState("");
  const [value, setValue] = useState<Date | null>(null);

  // Handle mobile view by using a media query hook
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Function to handle form submission
  const handleSubmit = () => {
    const formattedDate = value
      ? value.toLocaleString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      : "";
    const whatsappMessage = `Name: ${name}\nSurname: ${surname}\nMessage: ${message} \nDate: ${formattedDate}`;
    const phoneNumber = "+27762171825";
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappLink, "_blank");
  };

  // Wrap everything in Container conditionally
  const content = (
    <>
      <Stack align={isMobile ? "center" : "left"}>
        <Title
          order={1}
          size={isMobile ? 32 : 48} // Smaller title on mobile
          ta={isMobile ? "center" : "left"}
          style={{
            color: "rgba(255, 255, 255, .8)",
            fontFamily: "Playfair Display, sans-serif",
            fontWeight: 400,
          }}
        >
          Book Now
        </Title>
        <Title
          order={1}
          size={isMobile ? 20 : 24} // Smaller subtitle on mobile
          ta={isMobile ? "center" : "left"}
          style={{
            color: "rgba(255, 255, 255, .8)",
            fontFamily: "Playfair Display, sans-serif",
            fontWeight: 400,
          }}
        >
          Give us a call today and book an appointment in one of our salons.
        </Title>
      </Stack>
      <Flex
        direction={isMobile ? "column" : "row"} // Stack on mobile, row on desktop
        style={{ marginTop: "2rem", width: "100%" }}
        justify="center"
        align="center"
        gap="xl"
      >
        {/* Left side form */}
        <Stack gap="lg">
          <Input
            size="md"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: isMobile ? "100%" : 450,
            }}
          />
          <Input
            size="md"
            placeholder="Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            style={{
              width: isMobile ? "100%" : 450,
            }}
          />
          <Select
            size="md"
            value={message}
            onChange={(value: any) => setMessage(value)}
            placeholder="Select a service"
            data={[
              {
                group: "Ladies",
                items: ["Wash, Cut & Blowave", "Wash & Blowave"],
              },
              {
                group: "Gents",
                items: ["Cut", "Cut & Colour", "Cut & Highlight"],
              },
              {
                group: "Kids",
                items: ["Girls Cut & Blowave", "Boys Cut & Style"],
              },
              {
                group: "Colour",
                items: ["Full Head Colour", "T Section", "Roots"],
              },
              {
                group: "Traditional Highlights",
                items: ["Full Head Highlights"],
              },
              {
                group: "Advanced Lightning",
                items: ["Balayage", "Ombre", "Colour Removal"],
              },
              {
                group: "Toner",
                items: ["Toner"],
              },
              {
                group: "Mask",
                items: ["Mask"],
              },
            ]}
          />
          <DateTimePicker
            size="md"
            value={value}
            onChange={setValue}
            placeholder="Select Date"
          />
          <Button
            size="xl"
            style={{ marginTop: "1rem", width: isMobile ? "100%" : 450 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>

        {/* Vertical divider */}
        {!isMobile && <Divider orientation="vertical" size="lg" c="white" />}

        {/* Right side content */}
        <Stack gap="lg">
          {/* Open Google Maps */}
          <a
            href="https://www.google.com/maps/search/?api=1&query=366+Hippo+Ave,+Zwartkop,+Centurion"
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: "none" }} // Remove the underline from the link
          >
            <Group align="center">
              <IconMapPin size={isMobile ? 20 : 24} color="white" />
              <Text
                style={{
                  color: "rgba(255, 255, 255, .8)",
                  fontFamily: "Playfair Display, sans-serif",
                  fontSize: isMobile ? 16 : 20, // Adjust text size
                }}
              >
                366 Hippo Ave, Zwartkop, Centurion
              </Text>
            </Group>
          </a>

          {/* Open Phone Dialer */}
          <a href="tel:+27762171825" style={{ textDecoration: "none" }}>
            <Group align="center">
              <IconPhone size={isMobile ? 20 : 24} color="white" />
              <Text
                style={{
                  color: "rgba(255, 255, 255, .8)",
                  fontFamily: "Playfair Display, sans-serif",
                  fontSize: isMobile ? 16 : 20,
                }}
              >
                +27 076 217 1825
              </Text>
            </Group>
          </a>

          {/* Open WhatsApp */}
          <a
            href="https://wa.me/27762171825"
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: "none" }}
          >
            <Group align="center">
              <IconBrandWhatsapp size={isMobile ? 20 : 24} color="white" />
              <Text
                style={{
                  color: "rgba(255, 255, 255, .8)",
                  fontFamily: "Playfair Display, sans-serif",
                  fontSize: isMobile ? 16 : 20,
                }}
              >
                +27 076 217 1825
              </Text>
            </Group>
          </a>
        </Stack>
      </Flex>
    </>
  );

  return (
    <FullBackground
      backgroundColor="#000000"
      id={"book"}
      borderRadius="32px 32px 0px 0px"
    >
      <Flex
        direction="column"
        style={{ width: "100%" }}
        justify="center"
        align="center"
      >
        {" "}
        {isMobile ? content : <Container>{content}</Container>}
      </Flex>
    </FullBackground>
  );
};

export default Book;
