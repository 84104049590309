import {
  Flex,
  Group,
  SimpleGrid,
  Title,
  Text,
  Paper,
  useMantineTheme,
  Stack,
} from "@mantine/core";
import FullBackground from "../layout/FullBackground";
import {
  advancedLightning,
  bcBlondeMeMask,
  blondeMeVitaCShotMask,
  colour,
  gents,
  kids,
  ladies,
  toner,
  traditionalHighlights,
} from "../pricelist";
import { useMediaQuery } from "@mantine/hooks";

const Pricelist = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const renderServiceItems = (items: any) =>
    items.map((x: any) =>
      isMobile ? (
        <Stack
          gap={0}
          align="center"
          justify="space-between"
          key={x.label}
          mb={12}
        >
          <Text c="dark" style={{ fontWeight: "bold" }}>
            {x.label}
          </Text>
          <Text c="dark">R {x.value}</Text>
        </Stack>
      ) : (
        <Group justify="space-between" key={x.label}>
          <Text c="dark" style={{ fontWeight: "bold" }}>
            {x.label}
          </Text>
          <Text c="dark">R {x.value}</Text>
        </Group>
      )
    );

  return (
    <FullBackground id={"pricelist"} borderRadius="0px 0px 32px 32px">
      <Flex direction="column" style={{ width: "100%" }}>
        <Title
          order={1}
          size={64}
          m={32}
          style={{
            color: "rgba(0, 0, 0, .8)",
            fontFamily: "Playfair Display, sans-serif",
            fontWeight: 400,
          }}
        >
          Pricelist
        </Title>
        <SimpleGrid cols={isMobile ? 1 : 3}>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              Ladies
            </Title>
            {renderServiceItems(ladies)}
          </Paper>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              Gents
            </Title>
            {renderServiceItems(gents)}
          </Paper>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              Kids
            </Title>
            {renderServiceItems(kids)}
          </Paper>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              Colour
            </Title>
            {renderServiceItems(colour)}
          </Paper>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              Traditional Highlights
            </Title>
            {renderServiceItems(traditionalHighlights)}
          </Paper>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              Advanced Lightning <br />
              <Text size="xs" style={{ fontFamily: "Roboto, sans-serif" }}>
                Balayage, Ombre, Colour Removal
              </Text>
            </Title>
            {renderServiceItems(advancedLightning)}
          </Paper>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              Toner
            </Title>
            {renderServiceItems(toner)}
          </Paper>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              BC / BlondeMe Mask
            </Title>
            {renderServiceItems(bcBlondeMeMask)}
          </Paper>
          <Paper shadow="xs" radius="md" p="xl">
            <Title
              style={{
                textAlign: isMobile ? "center" : "left", // Conditional textAlign
                fontFamily: "Great Vibes, cursive",
                fontWeight: 400,
              }}
              c="dark"
              mb="lg"
            >
              BlondeMe Vita C Shot Mask
            </Title>
            {renderServiceItems(blondeMeVitaCShotMask)}
          </Paper>
        </SimpleGrid>
      </Flex>
    </FullBackground>
  );
};

export default Pricelist;
